import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

interface ImageModalProps {
	imageUrl: string
	alt: string
	onClose: () => void
}

export function ImageModal({ imageUrl, alt, onClose }: ImageModalProps) {
	const modalRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose()
			}
		}

		document.addEventListener('keydown', handleKeyDown)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [onClose])

	const handleClickOutside = (event: React.MouseEvent) => {
		if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
			onClose()
		}
	}

	return createPortal(
		<div
			className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
			onClick={handleClickOutside}
		>
			<div ref={modalRef} className="relative max-h-[90vh] max-w-[90vw]">
				<div className="overflow-hidden rounded-lg border-8 border-white shadow-2xl">
					<div className="bg-gradient-to-r from-blue-500 to-purple-500 p-1">
						<img
							src={imageUrl}
							alt={alt}
							className="max-h-[calc(90vh-4rem)] max-w-[calc(90vw-4rem)] object-contain"
						/>
					</div>
				</div>
				<button
					onClick={onClose}
					className="absolute -right-4 -top-4 rounded-full bg-white p-2 text-xl font-bold text-black shadow-md hover:bg-gray-200"
				>
					×
				</button>
			</div>
		</div>,
		document.body,
	)
}
