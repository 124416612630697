import { Link, type MetaFunction } from '@remix-run/react'
import { useState } from 'react'
import Developer from '#app/components/IndexPage/Developer.js'
import { ImageModal } from '#app/components/IndexPage/ImageModal'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '#app/components/ui/carousel.tsx'
// import {
// 	Tooltip,
// 	TooltipContent,
// 	TooltipProvider,
// 	TooltipTrigger,
// } from '#app/components/ui/tooltip.tsx'
// import { cn } from '#app/utils/misc.tsx'
import {
	cimg01,
	cimg02,
	cimg03,
	cimg04,
	cimg05,
	cimg06,
	cimg07,
	cimg08,
	cimg09,
	cimg10,
	cimg11,
	cimg12,
	cimg13,
	cimg14,
	cimg15,
	cimg16,
	cimg17,
	cimg18,
	cimg19,
	cimg20,
	cs_h_01,
	cs_h_02,
	cs_h_03,
	cs_h_04,
	cs_h_05,
	cs_h_06,
	cs_w_01,
	// cs_w_02,
	cs_w_03,
	cs_w_04,
	cs_w_05,
	cs_w_06,
	cs_w_07,
	// cs_w_08,
	cs_w_09,
	cs_w_10,
	cs_w_11,
	cs_w_12,
	cs_w_15,
	cs_w_16,
	visitedPlaces,
} from './chadimages/chadimages'
import { bg01 } from './runningImages/runningImages'

//import { logos } from './logos/logos.ts'

export const meta: MetaFunction = () => [
	{ title: 'Chad is Rad - ChadCluff.com' },
]

// Tailwind Grid cell classes lookup
// const columnClasses: Record<(typeof logos)[number]['column'], string> = {
// 	1: 'xl:col-start-1',
// 	2: 'xl:col-start-2',
// 	3: 'xl:col-start-3',
// 	4: 'xl:col-start-4',
// 	5: 'xl:col-start-5',
// }
// const rowClasses: Record<(typeof logos)[number]['row'], string> = {
// 	1: 'xl:row-start-1',
// 	2: 'xl:row-start-2',
// 	3: 'xl:row-start-3',
// 	4: 'xl:row-start-4',
// 	5: 'xl:row-start-5',
// 	6: 'xl:row-start-6',
// }

export default function Index() {
	const [selectedImage, setSelectedImage] = useState<string | null>(null)

	return (
		<main className="font-poppins h-fullx grid grid-flow-row auto-rows-max place-items-center">
			<div className="grid place-items-center px-4 py-16 xl:grid-cols-1 xl:gap-24">
				<div className="flex max-w-4xl flex-col items-start text-center md:flex-row xl:order-2 xl:h-svh xl:items-start xl:text-left">
					<img
						src="/img/Chad_20240801.jpg"
						alt="Chad Cluff"
						className="flex w-80 animate-slide-left rounded-md [animation-delay:0.3s] [animation-fill-mode:backwards] xl:w-96 xl:animate-slide-top xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
					/>
					<div className="flex flex-col">
						<h1
							data-heading
							className="ml-2 mt-8 flex animate-slide-top rounded-lg bg-sky-500 bg-opacity-20 p-6 text-4xl font-medium text-foreground [animation-delay:0.3s] [animation-fill-mode:backwards] sm:-ml-20 md:text-5xl xl:mt-20 xl:animate-slide-left xl:text-6xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
						>
							Chad Cluff
						</h1>

						<p
							data-paragraph
							className="ml-6 mt-6 animate-slide-top text-xl/7 text-muted-foreground [animation-delay:0.8s] [animation-fill-mode:backwards] xl:mt-8 xl:animate-slide-left xl:text-xl/6 xl:leading-10 xl:[animation-delay:1s] xl:[animation-fill-mode:backwards]"
						>
							Everything you wanted to know about Chad Cluff
						</p>
						<div className="mx-auto ml-0 w-full md:ml-12">
							<Carousel className="ml-10 w-3/4 sm:max-w-md md:max-w-80 lg:max-w-md">
								<CarouselContent className="-ml-4">
									<CarouselItem className="basis-1/3 pl-4 md:basis-1/2 lg:basis-1/3">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg01}
											alt="Chad in the late 1980s"
											onClick={() => setSelectedImage(cimg01)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 pl-4 md:basis-1/2 lg:basis-1/3">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg02}
											alt="Chad in the early 1990s"
											onClick={() => setSelectedImage(cimg02)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 pl-4 md:basis-1/2 lg:basis-1/3">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg03}
											alt="Chad in the mid 1990s"
											onClick={() => setSelectedImage(cimg03)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 pl-4 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg04}
											alt="Chad in the late 1990s"
											onClick={() => setSelectedImage(cimg04)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/3">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg05}
											alt="Chad in the late 1990s or early 2000"
											onClick={() => setSelectedImage(cimg05)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg06}
											alt="Chad in 2002/2003"
											onClick={() => setSelectedImage(cimg06)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg07}
											alt="Chad in 2002 on a mission with President Clove"
											onClick={() => setSelectedImage(cimg07)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/3">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg08}
											alt="Chad in 2003, on a mission in Regina, Saskatchewan"
											onClick={() => setSelectedImage(cimg08)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg09}
											alt="Chad in May 2004 with Debra and Dennis"
											onClick={() => setSelectedImage(cimg09)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg10}
											alt="Chad in May 2004 with Sara"
											onClick={() => setSelectedImage(cimg10)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/3">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg11}
											alt="Chad in 2009"
											onClick={() => setSelectedImage(cimg11)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg12}
											alt="Chad in 2011"
											onClick={() => setSelectedImage(cimg12)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/3">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg13}
											alt="Chad in July 2016"
											onClick={() => setSelectedImage(cimg13)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg14}
											alt="Chad in 2017"
											onClick={() => setSelectedImage(cimg14)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg15}
											alt="Chad in July 2018"
											onClick={() => setSelectedImage(cimg15)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg16}
											alt="Chad in August 2018"
											onClick={() => setSelectedImage(cimg16)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg17}
											alt="Chad in August 2018"
											onClick={() => setSelectedImage(cimg17)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg18}
											alt="Chad in August 2022"
											onClick={() => setSelectedImage(cimg18)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/2">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg19}
											alt="Chad in January 2023"
											onClick={() => setSelectedImage(cimg19)}
										/>
									</CarouselItem>
									<CarouselItem className="basis-1/3 md:basis-1/2 lg:basis-1/3">
										<img
											className="h-40 w-full rounded-md object-cover object-center"
											src={cimg20}
											alt="Chad in January 2024"
											onClick={() => setSelectedImage(cimg20)}
										/>
									</CarouselItem>
								</CarouselContent>
								<CarouselPrevious className="" />
								<CarouselNext />
							</Carousel>
						</div>
					</div>
				</div>
				{/* <ul className="mt-16 flex max-w-3xl flex-wrap justify-center gap-2 sm:gap-4 xl:mt-0 xl:grid xl:grid-flow-col xl:grid-cols-5 xl:grid-rows-6">
					<TooltipProvider>
						{logos.map((logo, i) => (
							<li
								key={logo.href}
								className={cn(
									columnClasses[logo.column],
									rowClasses[logo.row],
									'animate-roll-reveal [animation-fill-mode:backwards]',
								)}
								style={{ animationDelay: `${i * 0.07}s` }}
							>
								<Tooltip>
									<TooltipTrigger asChild>
										<a
											href={logo.href}
											className="grid size-20 place-items-center rounded-2xl bg-violet-600/10 p-4 transition hover:-rotate-6 hover:bg-violet-600/15 dark:bg-violet-200 dark:hover:bg-violet-100 sm:size-24"
										>
											<img src={logo.src} alt="" />
										</a>
									</TooltipTrigger>
									<TooltipContent>{logo.alt}</TooltipContent>
								</Tooltip>
							</li>
						))}
					</TooltipProvider>
				</ul> */}
			</div>
			<div
				id="family"
				className="inset container grid w-full max-w-full flex-row items-center justify-center bg-gradient-to-r from-sky-500 to-violet-500 pb-12 pt-16 text-center dark:from-sky-900 dark:to-violet-900 xl:items-start xl:text-left"
			>
				<div className="flex flex-col justify-between gap-4 px-4 pt-12 sm:static sm:px-6 lg:px-8">
					<div className="sm:max-w-6xl">
						<h2 className="text-3xl font-bold tracking-tight text-gray-100 sm:text-6xl">
							Family
						</h2>
						<p className="mt-4 text-xl text-gray-200">
							I'm married to my wonderful wife, Sara. We were married in San
							Diego, California in 2004. We have a cat named Aviendha.
						</p>
					</div>
					<div>
						<div className="mt-2 overflow-clip">
							<div
								aria-hidden="true"
								className="flex items-center justify-center rounded-lg p-2 lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
							>
								<div className="flex items-center space-x-1 md:space-x-4 lg:space-x-8">
									<div className="grid flex-shrink-0 grid-cols-1 gap-y-2 md:gap-y-4 lg:gap-y-8">
										{[cs_w_01, cs_w_16, cs_w_03, cs_w_04].map((img, index) => (
											<div
												key={index}
												className="h-6 w-12 cursor-pointer overflow-hidden rounded-lg sm:h-24 sm:w-32 md:h-32 md:w-44 lg:h-32 lg:w-52 xl:h-44 xl:w-64"
												onClick={() => setSelectedImage(img)}
											>
												<img
													src={img}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
										))}
									</div>
									<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
										{[cs_h_01, cs_h_02, cs_h_03].map((img, index) => (
											<div
												key={index}
												className="h-12 w-6 cursor-pointer overflow-hidden rounded-lg sm:h-32 sm:w-24 md:h-44 md:w-32 lg:h-52 lg:w-32 xl:h-64 xl:w-44"
												onClick={() => setSelectedImage(img)}
											>
												<img
													src={img}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
										))}
									</div>
									<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
										{[cs_w_05, cs_w_06, cs_w_07, cs_w_15].map((img, index) => (
											<div
												key={index}
												className="h-6 w-12 cursor-pointer overflow-hidden rounded-lg sm:h-24 sm:w-32 md:h-32 md:w-44 lg:h-32 lg:w-52 xl:h-44 xl:w-64"
												onClick={() => setSelectedImage(img)}
											>
												<img
													src={img}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
										))}
									</div>
									<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
										{[cs_h_04, cs_h_05, cs_h_06].map((img, index) => (
											<div
												key={index}
												className="h-12 w-6 cursor-pointer overflow-hidden rounded-lg sm:h-32 sm:w-24 md:h-44 md:w-32 lg:h-52 lg:w-32 xl:h-64 xl:w-44"
												onClick={() => setSelectedImage(img)}
											>
												<img
													src={img}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
										))}
									</div>
									<div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
										{[cs_w_09, cs_w_10, cs_w_11, cs_w_12].map((img, index) => (
											<div
												key={index}
												className="h-6 w-12 cursor-pointer overflow-hidden rounded-lg sm:h-24 sm:w-32 md:h-32 md:w-44 lg:h-32 lg:w-52 xl:h-44 xl:w-64"
												onClick={() => setSelectedImage(img)}
											>
												<img
													src={img}
													alt=""
													className="h-full w-full object-cover object-center"
												/>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				id="running"
				className="grid min-h-svh w-full place-items-start overflow-hidden py-0 dark:bg-slate-900"
			>
				<div
					className="relative flex w-full max-w-full flex-col items-center bg-cover bg-center pt-24 text-center text-white xl:items-start xl:text-left"
					style={{ backgroundImage: `url(${bg01})` }}
				>
					<div className="absolute inset-0 bg-black opacity-60"></div>
					<div className="z-10 flex max-w-3xl flex-col self-center">
						<h2
							data-heading
							className="mt-8 animate-slide-top text-3xl font-medium [animation-delay:0.3s] [animation-fill-mode:backwards] md:text-3xl xl:mt-4 xl:animate-slide-left xl:text-4xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
						>
							Running
						</h2>
						<p>
							I took up running in 2015. I wanted a way to improve my health and
							stay active without having to rely on gathering together a group,
							like with basketball. It gave me a chance to do my own thing, at
							my own schedule.
						</p>
						<div className="h-full py-20 sm:py-24">
							<div className="mx-auto max-w-7xl px-6 lg:px-8">
								<dl className="grid grid-cols-1 gap-x-8 gap-y-8 text-center lg:grid-cols-3">
									<div className="mx-auto flex max-w-xs flex-col gap-y-4">
										<dt className="text-base leading-7">Marathon Races Run</dt>
										<dd className="order-first text-3xl font-semibold tracking-tight sm:text-5xl">
											1
										</dd>
									</div>
									<div className="mx-auto flex max-w-xs flex-col gap-y-4">
										<dt className="text-base leading-7">
											Half Marathon Races Run
										</dt>
										<dd className="order-first text-3xl font-semibold tracking-tight sm:text-5xl">
											49
										</dd>
									</div>
									<div className="mx-auto flex max-w-xs flex-col gap-y-4">
										<dt className="text-base leading-7">Miles tracked</dt>
										<dd className="order-first text-3xl font-semibold tracking-tight sm:text-5xl">
											9,200+
										</dd>
									</div>
								</dl>
							</div>
						</div>
						<div>
							<Link
								to="running"
								className="mt-4 inline-block rounded-full bg-blue-600 px-6 py-3 text-lg font-semibold text-white transition-colors hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
							>
								Explore My Running Journey
							</Link>
						</div>
					</div>
					<div className="z-10 my-2 flex self-center px-2 md:self-end">
						<iframe
							title="Strava Activity Summary"
							height="160px"
							width="300px"
							frameBorder="0"
							scrolling="no"
							src="https://www.strava.com/athletes/22533607/activity-summary/d5ac2d9dd513a16da9cd80eb0e03eae045e74508"
						></iframe>
					</div>
				</div>
			</div>
			<div
				id="travel"
				className="grid min-h-svh place-items-center overflow-hidden bg-white py-12 dark:bg-slate-900 sm:py-32"
			>
				<div className="mx-auto max-w-7xl px-6 align-middle lg:px-8">
					<div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 align-middle sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
						<div className="lg:pr-8 lg:pt-4">
							<div className="lg:max-w-lg">
								<p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
									World Travels
								</p>
								<p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-100">
									My wife and I love to travel and see new sites and visit and
									learn from different cultures.
								</p>
								<h3 className="mt-2 text-2xl text-gray-900 dark:text-gray-300">
									Notable Destinations
								</h3>
								<ul className="mt-0 text-lg text-gray-600 dark:text-gray-100">
									<li>Curaço</li>
									<li>Plitvice Lakes National Park, Croatia</li>
									<li>Paris, France</li>
									<li>Bonn, Germany</li>
									<li>Venice, Italy</li>
									<li>Xunatunich, Belize</li>
								</ul>
							</div>
						</div>
						<img
							src={visitedPlaces}
							alt="Map of countries we have visited"
							className="col-span-2 h-full w-full max-w-none rounded-xl object-cover object-center shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 md:w-[48rem] lg:-ml-0"
							width="2432"
							height="1442"
						/>
					</div>
				</div>
			</div>

			<div
				id="developer"
				className="grid grow flex-col items-center text-center xl:items-start xl:text-left"
			>
				<Developer />
				{/* <div className="bg-white py-24 sm:py-32">
					<div className="mx-auto max-w-7xl px-6 lg:px-8">
						<h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
							I'm a software engineer for my day job. I've worked with a number
							of different technologies and created solutions for organizations
							both huge and small.
						</h2>
						<div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
							<img
								alt="React"
								src={react}
								width={158}
								height={48}
								className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
							/>
							<img
								alt="Reform"
								src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
								width={158}
								height={48}
								className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
							/>
							<img
								alt="Tuple"
								src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
								width={158}
								height={48}
								className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
							/>
							<img
								alt="SavvyCal"
								src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
								width={158}
								height={48}
								className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
							/>
							<img
								alt="Statamic"
								src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
								width={158}
								height={48}
								className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
							/>
						</div>
					</div>
				</div> */}
			</div>
			{selectedImage && (
				<ImageModal
					imageUrl={selectedImage}
					alt="Family photo"
					onClose={() => setSelectedImage(null)}
				/>
			)}
		</main>
	)
}
